export const componentPaths = {
    "editar.estado": () => import("@/components/locais/estados/EditarEstado.vue"),
    "cadastro.estado": () => import("@/components/locais/estados/CadastroEstado.vue"),
    "clientes": () => import("@/components/comercial/clientes/ListagemClientes.vue"),
    "cadastro.Cliente": () => import("@/components/comercial/clientes/CadastroCliente.vue"),
    "editar.cliente": () => import("@/components/comercial/clientes/EditarCliente.vue"),
    "Usuário": () => import("@/components/acessos/Usuarios/PerfilUsuario"),
    "cadastro.Usuario": () => import("@/components/acessos/Usuarios/CadastroUsuario"),
    "Usuarios": () => import("@/components/acessos/Usuarios/ListagemUsuarios"),
    "editar.usuario": () => import("@/components/acessos/Usuarios/EditarUsuario"),
    "Pedidos": () => import("@/components/operacoes/pedidos/ListagemPedidos"),
    "cadastro.Pedido": () => import("@/components/operacoes/pedidos/CadastroPedido.vue"),
    "editar.pedido": () => import("@/components/operacoes/pedidos/EditarPedido"),
    "Inquilinos": () => import("@/components/software/inquilinos/ListagemInquilinos"),
    "cadastro.Inquilino": () => import("@/components/software/inquilinos/CadastroInquilino"),
    "editar.inquilino": () => import("@/components/software/inquilinos/EditarInquilino"),
    "configuracoes.inquilino": () => import("@/components/software/inquilinos/ConfiguracoesInquilino.vue"),
    "FuncionarioSindicatos": () => import("@/components/pessoal/funcionario-sindicato/ListagemFuncionarioSindicatos"),
    "cadastro.FuncionarioSindicato": () => import("@/components/pessoal/funcionario-sindicato/CadastroFuncionarioSindicato"),
    "editar.funcionariosindicato": () => import("@/components/pessoal/funcionario-sindicato/EditarFuncionarioSindicato"),
    "FuncionarioCargos": () => import("@/components/pessoal/funcionario-cargo/ListagemFuncionarioCargos"),
    "cadastro.FuncionarioCargo": () => import("@/components/pessoal/funcionario-cargo/CadastroFuncionarioCargo"),
    "editar.funcionariocargo": () => import("@/components/pessoal/funcionario-cargo/EditarFuncionarioCargo"),
    "FuncionarioFolhaPagamentoVerbas": () => import("@/components/pessoal/funcionario-folha-pagamento-verba/ListagemFuncionarioFolhaPagamentoVerba"),
    "editar.funcionariofolhapagamentoverba": () => import("@/components/pessoal/funcionario-folha-pagamento-verba/EditarFuncionarioFolhaPagamentoVerba"),
    "Funcionarios": () => import("@/components/pessoal/funcionarios/ListagemFuncionarios"),
    "cadastro.Funcionario": () => import("@/components/pessoal/funcionarios/CadastroFuncionario.vue"),
    "cadastro.FuncionarioResumed": () => import("@/components/pessoal/funcionarios-resume/CadastroFuncionarioResume.vue"),
    "editar.funcionario": () => import("@/components/pessoal/funcionarios/EditarFuncionario"),
    "FuncionarioPontos": () => import("@/components/pessoal/funcionario-ponto/ListagemFuncionarioPontos"),
    "cadastro.FuncionarioPonto": () => import("@/components/pessoal/funcionario-ponto/CadastroFuncionarioPonto"),
    "editar.funcionarioponto": () => import("@/components/pessoal/funcionario-ponto/EditarFuncionarioPonto"),
    "Paises": () => import("@/components/locais/paises/ListagemPaises"),
    "cadastro.Pais": () => import("@/components/locais/paises/CadastroPais"),
    "editar.pais": () => import("@/components/locais/paises/EditarPais"),
    "Pessoas": () => import("@/components/pessoal/pessoas/ListagemPessoas.vue"),
    "cadastro.Pessoa": () => import("@/components/pessoal/pessoas/CadastroPessoa.vue"),
    "editar.pessoa": () => import("@/components/pessoal/pessoas/EditarPessoa.vue"),
    "Estados": () => import("@/components/locais/estados/ListagemEstados.vue"),
    "cadastro.Estado": () => import("@/components/locais/estados/CadastroEstado.vue"),
    "editar.estado": () => import("@/components/locais/estados/EditarEstado.vue"),
    "Cidades": () => import("@/components/locais/cidades/ListagemCidades.vue"),
    "cadastro.Cidade": () => import("@/components/locais/cidades/CadastroCidade.vue"),
    "editar.cidade": () => import("@/components/locais/cidades/EditarCidade.vue"),
    "Veiculos": () => import("@/components/operacoes/veiculos/ListagemVeiculos.vue"),
    "cadastro.Veiculo": () => import("@/components/operacoes/veiculos/CadastroVeiculo.vue"),
    "editar.veiculo": () => import("@/components/operacoes/veiculos/EditarVeiculo.vue"),
    "VeiculoModelos": () => import("@/components/operacoes/veiculo-modelos/ListagemVeiculoModelos.vue"),
    "cadastro.VeiculoModelo": () => import("@/components/operacoes/veiculo-modelos/CadastroVeiculoModelo.vue"),
    "editar.veiculomodelo": () => import("@/components/operacoes/veiculo-modelos/EditarVeiculoModelo.vue"),
    "Roteiros": () => import("@/components/operacoes/roteiros/ListagemRoteiros.vue"),
    "cadastro.Roteiro": () => import("@/components/operacoes/roteiros/CadastroRoteiro.vue"),
    "editar.roteiro": () => import("@/components/operacoes/roteiros/EditarRoteiro.vue"),
    "RoteiroModelos": () => import("@/components/operacoes/roteiroModelos/ListagemRoteiroModelo.vue"),
    "cadastro.RoteiroModelo": () => import("@/components/operacoes/roteiroModelos/CadastroRoteiroModelo.vue"),
    "editar.roteiromodelo": () => import("@/components/operacoes/roteiroModelos/EditarRoteiroModelo.vue"),
    "GuiaTransporte": () => import("@/components/operacoes/guiaTransporte/ListagemGuiaTransporte.vue"),
    "Suprimentos": () => import("@/components/operacoes/tesouraria/Suprimento.vue"),
    "cadastro.Suprimento": () => import("@/components/operacoes/tesouraria/CadastroSuprimento.vue"),
    "editar.suprimento": () => import("@/components/operacoes/tesouraria/EditarSuprimento.vue"),
    "editar.roteiromodelo": () => import("@/components/operacoes/roteiroModelos/EditarRoteiroModelo.vue"),
    "Questionarios": () => import("@/components/operacoes/questionarios/ListagemQuestionarios.vue"),
    "cadastro.Questionario": () => import("@/components/operacoes/questionarios/CadastroQuestionario.vue"),
    "editar.roteirotrajeto": () => import("@/components/operacoes/roteiroTrajeto/EditarRoteiroTrajeto.vue"),
    "cadastro.RoteiroTrajeto": () => import("@/components/operacoes/roteiroTrajeto/CadastroRoteiroTrajeto.vue"),
    "Produtos": () => import("@/components/recursos/produtos/ListagemProdutos.vue"),
    "cadastro.Produto": () => import("@/components/recursos/produtos/CadastroProduto.vue"),
    "editar.produto": () => import("@/components/recursos/produtos/EditarProduto.vue"),
    "ProdutoCategorias": () => import("@/components/recursos/produto-categorias/ListagemProdutoCategorias.vue"),
    "cadastro.produtoCategoria": () => import("@/components/recursos/produto-categorias/CadastroProdutoCategoria.vue"),
    "editar.produtoCategoria": () => import("@/components/recursos/produto-categorias/EditarProdutoCategoria.vue"),
    "ContaBancarias": () => import("@/components/financeiro/conta-bancarias/ListagemContaBancarias.vue"),
    "cadastro.ContaBancaria": () => import("@/components/financeiro/conta-bancarias/CadastroContaBancaria.vue"),
    "editar.contabancaria": () => import("@/components/financeiro/conta-bancarias/EditarContaBancaria.vue"),
    "extrato.contabancaria": () => import("@/components/financeiro/conta-bancarias/Extrato.vue"),
    "fluxoDiario": () => import("@/components/financeiro/analises/FluxoDiario.vue"),
    "dfc.direto": () => import("@/components/financeiro/analises/dfc/DFC.vue"),
    "analiseCC": () => import("@/components/financeiro/analises/analiseCC/Analise.vue"),
    "CentroCustos": () => import("@/components/financeiro/centro-custos/ListagemCentroCustos.vue"),
    "CentroCustosAnalitico": () => import("@/components/financeiro/centro-custos/ListagemCentroCustosAnalitico.vue"),
    "cadastro.CentroCusto": () => import("@/components/financeiro/centro-custos/CadastroCentroCusto.vue"),
    "editar.centrocusto": () => import("@/components/financeiro/centro-custos/EditarCentroCusto.vue"),
    "ContaBancariaTransferencias": () => import("@/components/financeiro/conta-bancaria-transferencia/ListagemContaBancariaTransferencia.vue"),
    "cadastro.ContaBancariaTransferencia": () => import("@/components/financeiro/conta-bancaria-transferencia/CadastroContaBancariaTransferencia.vue"),
    "editar.contabancariatransferencia": () => import("@/components/financeiro/conta-bancaria-transferencia/EditarContaBancariaTransferencia.vue"),
    "Fornecedores": () => import("@/components/financeiro/fornecedores/ListagemFornecedores.vue"),
    "cadastro.Fornecedor": () => import("@/components/financeiro/fornecedores/CadastroFornecedor.vue"),
    "editar.fornecedor": () => import("@/components/financeiro/fornecedores/EditarFornecedor.vue"),
    "TituloReceber": () => import("@/components/financeiro/titulo-receber/ListagemTituloReceber.vue"),
    "TituloReceberPending": () => import("@/components/financeiro/titulo-receber/ListagemTituloReceberPending.vue"),
    "cadastro.TituloReceber": () => import("@/components/financeiro/titulo-receber/CadastroTituloReceber.vue"),
    "editar.tituloreceber": () => import("@/components/financeiro/titulo-receber/EditarTituloReceber.vue"),
    "RegistroRecebimentos": () => import("@/components/financeiro/registro-recebimentos/ListagemRegistroRecebimentos.vue"),
    "cadastro.RegistroRecebimento": () => import("@/components/financeiro/registro-recebimentos/CadastroRegistroRecebimento.vue"),
    "editar.registrorecebimento": () => import("@/components/financeiro/registro-recebimentos/EditarRegistroRecebimento.vue"),
    "TituloPagar": () => import("@/components/financeiro/titulo-pagar/ListagemTituloPagar.vue"),
    "TituloPagarPending": () => import("@/components/financeiro/titulo-pagar/ListagemTituloPagarPending.vue"),
    "cadastro.TituloPagar": () => import("@/components/financeiro/titulo-pagar/CadastroTituloPagar.vue"),
    "editar.titulopagar": () => import("@/components/financeiro/titulo-pagar/EditarTituloPagar.vue"),
    "RegistroPagamentos": () => import("@/components/financeiro/registro-pagamentos/ListagemRegistroPagamentos.vue"),
    "cadastro.RegistroPagamento": () => import("@/components/financeiro/registro-pagamentos/CadastroRegistroPagamento.vue"),
    "editar.registropagamento": () => import("@/components/financeiro/registro-pagamentos/EditarRegistroPagamento.vue"),
    "FuncionarioPontoCalculo": () => import("@/components/pessoal/funcionario-ponto/ListagemFuncionarioPontoCalculo.vue"),
    "FuncionarioPontoPeriodo": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/ListagemFuncionarioPontoPeriodos.vue"),
    "TutorialFuncionarioPontoPeriodo": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/TutorialListagemFuncionarioPontoPeriodos.vue"),
    "cadastro.FuncionarioPontoPeriodo": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/CadastroFuncionarioPontoPeriodo.vue"),
    "editar.funcionariopontoperiodo": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/EditarFuncionarioPontoPeriodo.vue"),
    "tutorial.editar.funcionariopontoperiodo": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo/TutorialEditarFuncionarioPontoPeriodo.vue"),
    "FuncionarioPontoPeriodoReducaoCargaHorariaColetiva": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo-reducao-carga-horaria-coletiva/ListagemFuncionarioPontoPeriodoReducaoCargaHorariaColetiva.vue"),
    "cadastro.FuncionarioPontoPeriodoReducaoCargaHorariaColetiva": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo-reducao-carga-horaria-coletiva/CadastroFuncionarioPontoPeriodoReducaoCargaHorariaColetiva.vue"),
    "editar.funcionariopontoperiodoreducaocargahorariacoletiva": () => import("@/components/pessoal/funcionario-ponto/funcionario-ponto-periodo-reducao-carga-horaria-coletiva/EditarFuncionarioPontoPeriodoReducaoCargaHorariaColetiva.vue"),
    "ParametrizacaoFiscal": () => import("@/components/comercial/parametrizacao-fiscal/ListagemParametrizacaoFiscal.vue"),
    "cadastro.ParametrizacaoFiscal": () => import("@/components/comercial/parametrizacao-fiscal/CadastroParametrizacaoFiscal.vue"),
    "editar.parametrizacaofiscal": () => import("@/components/comercial/parametrizacao-fiscal/EditarParametrizacaoFiscal.vue"),
    "Contratos": () => import("@/components/comercial/contratos/ListagemContratos.vue"),
    "cadastro.Contrato": () => import("@/components/comercial/contratos/CadastroContrato.vue"),
    "editar.contrato": () => import("@/components/comercial/contratos/EditarContrato.vue"),
    "EscopoServicos": () => import("@/components/comercial/escopo-servicos/ListagemEscopoServicos.vue"),
    "cadastro.EscopoServico": () => import("@/components/comercial/escopo-servicos/CadastroEscopoServico.vue"),
    "editar.escoposervico": () => import("@/components/comercial/escopo-servicos/EditarEscopoServico.vue"),
    "ParametrizacaoServicos": () => import("@/components/comercial/parametrizacao-servicos/ListagemParametrizacaoServicos.vue"),
    "cadastro.ParametrizacaoServico": () => import("@/components/comercial/parametrizacao-servicos/CadastroParametrizacaoServico.vue"),
    "editar.parametrizacaoservico": () => import("@/components/comercial/parametrizacao-servicos/EditarParametrizacaoServico.vue"),
    "PostoTrabalhos": () => import("@/components/comercial/posto-trabalho/ListagemPostoTrabalhos.vue"),
    "cadastro.PostoTrabalho": () => import("@/components/comercial/posto-trabalho/CadastroPostoTrabalho.vue"),
    "editar.postotrabalho": () => import("@/components/comercial/posto-trabalho/EditarPostoTrabalho.vue"),
    "FuncionarioEscalaTrabalhos": () => import("@/components/pessoal/funcionario-escala-trabalho/ListagemFuncionarioEscalaTrabalhos.vue"),
    "cadastro.FuncionarioEscalaTrabalho": () => import("@/components/pessoal/funcionario-escala-trabalho/CadastroFuncionarioEscalaTrabalho.vue"),
    "editar.funcionarioescalatrabalho": () => import("@/components/pessoal/funcionario-escala-trabalho/EditarFuncionarioEscalaTrabalho.vue"),
    "FuncionarioMovimentacoes": () => import("@/components/pessoal/funcionario-movimentacao/ListagemFuncionarioMovimentacoes.vue"),
    "cadastro.FuncionarioMovimentacoes": () => import("@/components/pessoal/funcionario-movimentacao/CadastroFuncionarioMovimentacao.vue"),
    "editar.funcionariomovimentacoes": () => import("@/components/pessoal/funcionario-movimentacao/EditarFuncionarioMovimentacao.vue"),
    "Cfops": () => import("@/components/comercial/cfop/ListagemCfops.vue"),
    "cadastro.Cfop": () => import("@/components/comercial/cfop/CadastroCfop.vue"),
    "editar.cfop": () => import("@/components/comercial/cfop/EditarCfop.vue"),
    "cfopCidades": () => import("@/components/comercial/cfopCidades/ListagemCfopCidades.vue"),
    "cadastro.CfopCidade": () => import("@/components/comercial/cfopCidades/CadastroCfopCidade.vue"),
    "editar.cfopcidade": () => import("@/components/comercial/cfopCidades/EditarCfopCidade.vue"),
    "cfopEstados": () => import("@/components/comercial/cfopEstados/ListagemCfopEstados.vue"),
    "cadastro.CfopEstado": () => import("@/components/comercial/cfopEstados/CadastroCfopEstado.vue"),
    "editar.cfopestado": () => import("@/components/comercial/cfopEstados/EditarCfopEstado.vue"),
    "cadastro.EmailMarketing": () => import("@/components/email-marketing/EnviarEmailMarketing.vue"),
    "EmailMarketing": () => import("@/components/email-marketing/ListagemEmailMarketing.vue"),
    "GraficosPeriodo": () => import("@/components/pessoal/funcionario-ponto/graficos/modelo01/Graficos.vue"),
    "GraficosPeriodoMod02": () => import("@/components/pessoal/funcionario-ponto/graficos/modelo02/Graficos.vue"),
    "funcionarioAfastamentos": () => import("@/components/pessoal/funcionario-afastamento/ListagemFuncionarioAfastamentos.vue"),
    "cadastro.FuncionarioAfastamento": () => import("@/components/pessoal/funcionario-afastamento/CadastroFuncionarioAfastamento.vue"),
    "editar.funcionarioafastamento": () => import("@/components/pessoal/funcionario-afastamento/EditarFuncionarioAfastamento.vue"),
    "FuncionarioBancoHorasContratos": () => import("@/components/pessoal/banco-de-horas/ListagemBancoHoras.vue"),
    "cadastro.FuncionarioBancoHorasContrato": () => import("@/components/pessoal/banco-de-horas/CadastroBancoHora.vue"),
    "cadastro.FuncionarioBancoHorasContratosSaldo": () => import("@/components/pessoal/banco-de-horas/CadastroBancoHoraSaldo.vue"),
    "editar.funcionariobancohorascontrato": () => import("@/components/pessoal/banco-de-horas/EditarBancoHora.vue"),
    "gestaoEfetivo": () => import("@/components/pessoal/gestao-efetivo/ListagemGestaoEfetivo"),
    "PedidoMateriais": () => import("@/components/recursos/pedido-material/ListagemPedidos"),
    "cadastro.PedidoMaterial": () => import("@/components/recursos/pedido-material/CadastroPedido"),
    "editar.pedidomaterial": () => import("@/components/recursos/pedido-material/EditarPedido"),
    "PedidoUniformes": () => import("@/components/recursos/pedido-uniforme/ListagemPedidos"),
    "cadastro.PedidoUniforme": () => import("@/components/recursos/pedido-uniforme/CadastroPedido"),
    "editar.pedidouniforme": () => import("@/components/recursos/pedido-uniforme/EditarPedido"),
    "PedidoConsumiveis": () => import("@/components/recursos/pedido-consumivel/ListagemPedidos"),
    "cadastro.PedidoConsumivel": () => import("@/components/recursos/pedido-consumivel/CadastroPedido"),
    "editar.pedidoconsumivel": () => import("@/components/recursos/pedido-consumivel/EditarPedido"),
    "ContaFinanceiras": () => import("@/components/financeiro/conta-financeira/ListagemContaFinanceira"),
    "ContaFinanceirasConta": () => import("@/components/financeiro/conta-financeira/ListagemContaFinanceiraConta"),
    "cadastro.ContaFinanceira": () => import("@/components/financeiro/conta-financeira/CadastroContaFinanceira"),
    "editar.contafinanceira": () => import("@/components/financeiro/conta-financeira/EditarContaFinanceira"),
    "Imobilizados": () => import("@/components/recursos/imobilizados/ListagemImobilizado"),
    "cadastro.Imobilizado": () => import("@/components/recursos/imobilizados/CadastroImobilizado"),
    "editar.imobilizado": () => import("@/components/recursos/imobilizados/EditarImobilizado"),
    "ImobilizadoMovimentacoes": () => import("@/components/recursos/imobilizado-movimentacoes/ListagemImobilizadoMovimentacao"),
    "cadastro.ImobilizadoMovimentacao": () => import("@/components/recursos/imobilizado-movimentacoes/CadastroImobilizadoMovimentacao"),
    "editar.imobilizadomovimentacao": () => import("@/components/recursos/imobilizado-movimentacoes/EditarImobilizadoMovimentacao"),
    "FuncionarioFolhaPagamentoPeriodos": () => import("@/components/pessoal/funcionario-folha-pagamento-periodo/ListagemFuncionarioFolhaPagamentoPeriodo"),
    "cadastro.FuncionarioFolhaPagamentoPeriodo": () => import("@/components/pessoal/funcionario-folha-pagamento-periodo/CadastroFuncionarioFolhaPagamentoPeriodo"),
    "editar.funcionariofolhapagamentoperiodo": () => import("@/components/pessoal/funcionario-folha-pagamento-periodo/EditarFuncionarioFolhaPagamentoPeriodo"),
    "CalculoFaturamento": () => import("@/components/faturamento/calculo-faturamento/ListagemCalculoFaturamento"),
    "PracaFiscais": () => import("@/components/faturamento/praca-fiscal/ListagemPracaFiscal"),
    "cadastro.FaturamentoPracaFiscal": () => import("@/components/faturamento/praca-fiscal/CadastroPracaFiscal"),
    "editar.faturamentopracafiscal": () => import("@/components/faturamento/praca-fiscal/EditarPracaFiscal"),
    "DocumentoFiscal": () => import("@/components/faturamento/documento-fiscal/ListagemDocumentoFiscal"),
    "cadastro.faturamentodocumentofiscal": () => import("@/components/faturamento/documento-fiscal/CadastroDocumentoFiscal"),
    "editar.faturamentodocumentofiscal": () => import("@/components/faturamento/documento-fiscal/EditarDocumentoFiscal"),
    "BeneficioConvenios": () => import("@/components/pessoal/beneficio/convenio/ListagemBeneficioConvenio"),
    "cadastro.BeneficioConvenio": () => import("@/components/pessoal/beneficio/convenio/CadastroBeneficioConvenio"),
    "editar.beneficioconvenio": () => import("@/components/pessoal/beneficio/convenio/EditarBeneficioConvenio"),
    "BeneficioConvenioOptantes": () => import("@/components/pessoal/beneficio/optante/ListagemBeneficioConvenioOptante"),
    "cadastro.BeneficioConvenioOptante": () => import("@/components/pessoal/beneficio/optante/CadastroBeneficioConvenioOptante"),
    "editar.beneficioconveniooptante": () => import("@/components/pessoal/beneficio/optante/EditarBeneficioConvenioOptante"),
    "BeneficioPeriodoApuracoes": () => import("@/components/pessoal/beneficio/periodo/ListagemBeneficioPeriodoApuracao"),
    "cadastro.BeneficioPeriodoApuracao": () => import("@/components/pessoal/beneficio/periodo/CadastroBeneficioPeriodoApuracao"),
    "editar.beneficioperiodoapuracao": () => import("@/components/pessoal/beneficio/periodo/EditarBeneficioPeriodoApuracao"),
    "BeneficioApuracao": () => import("@/components/pessoal/beneficio/calculo/ListagemBeneficioApuracao"),
    "usuarioPerfis": () => import("@/components/acessos/perfis/ListagemPerfil"),
    "cadastro.UsuarioPerfil": () => import("@/components/acessos/perfis/CadastroPerfil"),
    "editar.usuarioperfil": () => import("@/components/acessos/perfis/EditarPerfil"),
    "permissoes": () => import("@/components/acessos/permissoes/ListagemPermissao"),
    "cadastro.Permissao": () => import("@/components/acessos/permissoes/CadastroPermissao"),
    "editar.permissao": () => import("@/components/acessos/permissoes/EditarPermissao"),
    "beneficio.analise.cliente": () => import("@/components/pessoal/beneficio/analise-cliente/Realizado"),
    "MetodoCobrancas": () => import("@/components/comercial/metodo-cobranca/ListagemMetodoCobranca"),
    "cadastro.MetodoCobranca": () => import("@/components/comercial/metodo-cobranca/CadastroMetodoCobranca"),
    "editar.metodocobranca": () => import("@/components/comercial/metodo-cobranca/EditarMetodoCobranca"),
    "CompraPedidos": () => import("@/components/recursos/compras/compra-pedido/ListagemCompraPedido"),
    "cadastro.CompraPedido": () => import("@/components/recursos/compras/compra-pedido/CadastroCompraPedido"),
    "editar.comprapedido": () => import("@/components/recursos/compras/compra-pedido/EditarCompraPedido"),
    "CompraOrcamentos": () => import("@/components/recursos/compras/compra-orcamento/ListagemCompraOrcamento"),
    "cadastro.CompraOrcamento": () => import("@/components/recursos/compras/compra-orcamento/CadastroCompraOrcamento"),
    "editar.compraorcamento": () => import("@/components/recursos/compras/compra-orcamento/EditarCompraOrcamento"),
    "CompraOrdens": () => import("@/components/recursos/compras/compra-ordem/ListagemCompraOrdem"),
    "cadastro.Feriado": () => import("@/components/software/feriados/CadastroFeriado"),
    "editar.feriado": () => import("@/components/software/feriados/EditarFeriado"),
    "Feriados": () => import("@/components/software/feriados/ListagemFeriados"),
    "faturamentovariaveisservicos": () => import("@/components/faturamento/variaveis-servico/ListagemVariaveisServico"),
    "cadastro.FaturamentoVariaveisServico": () => import("@/components/faturamento/variaveis-servico/CadastroVariaveisServico"),
    "editar.faturamentovariaveisservico": () => import("@/components/faturamento/variaveis-servico/EditarVariaveisServico"),
    "registrotarefaperiodo": () => import("@/components/operacoes/tarefas/periodo/ListagemTarefasPeriodos"),
    "cadastro.RegistroTarefaPeriodo": () => import("@/components/operacoes/tarefas/periodo/CadastroTarefasPeriodo"),
    "editar.registrotarefaperiodo": () => import("@/components/operacoes/tarefas/periodo/EditarTarefasPeriodo"),
    "RegistroTarefaCalculo": () => import("@/components/operacoes/tarefas/calculo/ListagemRegistrosTarefaApuracao"),
    "lancamentosContabeis": () => import("@/components/contabilidade/LancamentoContabil"),
    "AvisoPrevio": () => import("@/components/pessoal/funcionario-aviso-previo/ListagemAvisoPrevio"),
    "cadastro.FuncionarioAvisoPrevio": () => import("@/components/pessoal/funcionario-aviso-previo/CadastroAvisoPrevio"),
    "editar.funcionarioavisoprevio": () => import("@/components/pessoal/funcionario-aviso-previo/EditarAvisoPrevio"),
    "ModeloDocumentacaoAcessoria": () => import("@/components/pessoal/modelo-documentacao-acessoria/ListagemModeloDocumentacaoAcessoria"),
    "cadastro.ModeloDocumentacaoAcessoria": () => import("@/components/pessoal/modelo-documentacao-acessoria/CadastroModeloDocumentacaoAcessoria"),
    "editar.modelodocumentacaoacessoria": () => import("@/components/pessoal/modelo-documentacao-acessoria/EditarModeloDocumentacaoAcessoria"),
    "OrcamentoDespesa": () => import("@/components/financeiro/orcamento-despesa/ListagemOrcamentoDespesa"),
    "funcionarioAfastamentoSolicitacao": () => import("@/components/pessoal/funcionario-afastamento-solicitacao/ListagemFuncionarioAfastamentoSolicitacao"),
    "cadastro.FuncionarioAfastamentoSolicitacao": () => import("@/components/pessoal/funcionario-afastamento-solicitacao/CadastroFuncionarioAfastamentoSolicitacao"),
    "importarHorarios": () => import("@/components/pessoal/funcionario-escala-trabalho/GestaoAcademicaHorarios"),
    "ContaVinculadaMovimentacoes": () => import("@/components/financeiro/conta-vinculada-movimentacao/ListagemContaVinculadaMovimentacao"),
    "cadastro.ContaVinculadaMovimentacao": () => import("@/components/financeiro/conta-vinculada-movimentacao/CadastroContaVinculadaMovimentacao"),
    "editar.contavinculadamovimentacao": () => import("@/components/financeiro/conta-vinculada-movimentacao/EditarContaVinculadaMovimentacao"),
    "cadastro.Equipamento": () => import("@/components/operacoes/equipamentos/CadastroEquipamento"),
    "editar.equipamento": () => import("@/components/operacoes/equipamentos/EditarEquipamento"),
    "cadastro.CodigoIdentificacaoReciproca": () => import("@/components/operacoes/codigoIdentificacaoReciproca/CadastroCodigoIdentificacaoReciproca"),
    "editar.codigoidentificacaoreciproca": () => import("@/components/operacoes/codigoIdentificacaoReciproca/EditarCodigoIdentificacaoReciproca"),
    "cadastro.LogisticaTipoEvento": () => import("@/components/operacoes/logisticaTipoEvento/CadastroLogisticaTipoEvento"),
    "editar.logisticatipoevento": () => import("@/components/operacoes/logisticaTipoEvento/EditarLogisticaTipoEvento"),
    "cadastro.LogisticaTipoOcorrencia": () => import("@/components/operacoes/logisticaTipoOcorrencia/CadastroLogisticaTipoOcorrencia"),
    "editar.logisticatipoocorrencia": () => import("@/components/operacoes/logisticaTipoOcorrencia/EditarLogisticaTipoOcorrencia")

};
