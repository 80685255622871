import { render, staticRenderFns } from "./MaterialTabs.vue?vue&type=template&id=88472d72&"
import script from "./MaterialTabs.vue?vue&type=script&lang=js&"
export * from "./MaterialTabs.vue?vue&type=script&lang=js&"
import style0 from "./MaterialTabs.vue?vue&type=style&index=0&id=88472d72&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTabs})
