<template>
  <div class="comp-superset">
    <div class="listagem-finais">
      <div class="card-header-padding">
        <div>
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            {{ $t(tituloFinais) }}
          </h5>
          <p class="text-sm text-body mb-0">
            {{ $t(subtituloFinais) }}
          </p>
        </div>
      </div>

      <div class="d-flex align-center" v-if="pesquisa">
        <v-col cols="12" md="5">
          <v-text-field
            hide-details
            class="input-style font-size-input text-light-input placeholder-light input-icon"
            dense
            flat
            filled
            solo
            height="39"
            v-model="search"
            :label="$t('Procurar')"
          >
            <template slot="prepend-inner">
              <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
            </template></v-text-field
          >
        </v-col>
      </div>
      <v-col cols="12" v-if="subTotal">
        <div class="font-weight-bold text-h6 text-typo mb-0">
          <p id="saldo">
            Subtotal:
            {{ subTotalValor | formatoMonetario }}
          </p>
        </div>
      </v-col>
      <v-data-table
        :headers="headersFinais"
        :items="recursosFinais"
        :search="search"
        :items-per-page="pageSize"
        :item-class="isStatusComum"
        class="table"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex justify-start">
            <v-icon
              v-if="podeEditarRecursoFinal"
              small
              @click="editaRecursoFinal(item)"
              class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
              color="#67748e"
              >ni-ruler-pencil</v-icon
            >
            <ConfirmaAcao @confirm="removeRecursoFinal(item)">
              <v-icon
                v-if="podeExcluirRecursoFinal"
                class="ma-1 btn-ls me-0.90 my-1 rounded-sm"
                color="#67748e"
                small
                >ni-fat-remove</v-icon
              >
            </ConfirmaAcao>
          </div>
        </template>

        <template v-slot:[`item.juros`]="{ item }">
          <span>{{ item.juros | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.desconto`]="{ item }">
          <span>{{ item.desconto | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.percentual`]="{ item }">
          <span>{{ item.percentual | formatoPercentualExato }}</span>
        </template>
        <template v-slot:[`item.baseTributacao`]="{ item }">
          <span>{{ item.baseTributacao | formatoPercentualExato }}</span>
        </template>
        <template v-slot:[`item.valorUnitario`]="{ item }">
          <span>{{ item.valorUnitario | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.multa`]="{ item }">
          <span>{{ item.multa | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.diaSemana`]="{ item }">
          <span>{{ item.diaSemana | capitalize }}</span>
        </template>
        <template v-slot:[`item.outrosValores`]="{ item }">
          <span>{{ item.outrosValores | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.pagamentoParcial`]="{ item }">
          <span>{{ item.pagamentoParcial | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.recebimentoParcial`]="{ item }">
          <span>{{ item.recebimentoParcial | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.valorFinal`]="{ item }">
          <span>{{ item.valorFinal | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.tituloPagar.vencimento`]="{ item }">
          <span>{{ item.tituloPagar.vencimento | dataParaLeitura }}</span>
        </template>
        <template v-slot:[`item.tituloReceber.vencimento`]="{ item }">
          <span>{{ item.tituloReceber.vencimento | dataParaLeitura }}</span>
        </template>
        <template v-slot:[`item.tituloReceber.valor`]="{ item }">
          <span>{{ item.tituloReceber.valor | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.tituloPagar.valor`]="{ item }">
          <span>{{ item.tituloPagar.valor | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.encerramentoJornada`]="{ item }">
          <span>{{ item.encerramentoJornada | dataHoraParaLeitura }}</span>
        </template>
        <template v-slot:[`item.inicioJornada`]="{ item }">
          <span>{{ item.inicioJornada | dataHoraParaLeitura }}</span>
        </template>
        <template v-slot:[`item.tipo`]="{ item }">
          <span>{{ $t(item.tipo) }}</span>
        </template>
        <!-- <template v-slot:[`item.ativo`]="{ item }">
            <span>{{ $t(item.ativo.toString()) }}</span>
          </template> -->
        <template v-slot:[`item.tipoApuracao`]="{ item }">
          <span>{{ $t(item.tipoApuracao) }}</span>
        </template>
        <template v-slot:[`item.ativo`]="{ item }">
          <span>{{ $t(item.ativo) ? $t("sim") : $t("nao") }}</span>
        </template>
        <template v-slot:[`item.retencao`]="{ item }">
          <span>{{ $t(item.retencao) ? $t("sim") : $t("nao") }}</span>
        </template>
        <template v-slot:[`item.valor`]="{ item }">
          <span>{{ item.valor | formatoMonetario }}</span>
        </template>
        <template v-slot:[`item.dataVencimento`]="{ item }">
          <span>{{ item.dataVencimento | dataParaLeitura }}</span>
        </template>
        <template v-slot:[`item.finalVigencia`]="{ item }">
          <span>{{ item.finalVigencia | dataParaLeitura }} </span>
        </template>
        <template v-slot:[`item.inicioVigencia`]="{ item }">
          <span>{{ item.inicioVigencia | dataParaLeitura }} </span>
        </template>
        <template v-slot:[`item.dtAlteracao`]="{ item }">
          <span>{{ item.dtAlteracao | dataHoraParaLeitura }} </span>
        </template>
        <template v-slot:[`item.apuracao`]="{ item }">
          <span>{{ $t(item.apuracao) }}</span>
        </template>
        <template v-slot:[`item.nascimento`]="{ item }">
          <span>{{ item.nascimento | dataParaLeitura }} </span>
        </template>
        <template v-slot:[`item.inicio`]="{ item }">
          <span>{{ item.inicio | dataHoraParaLeitura }} </span>
        </template>
        <template v-slot:[`item.dtInclusao`]="{ item }">
          <span>{{ item.dtInclusao | dataHoraParaLeitura }} </span>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-btn
          id="btn--CriarItemSuperSet"
          @click.stop="clickNewRecord()"
          class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
          height="39"
        >
          <v-icon left dark> mdi-plus-circle </v-icon>
          {{ $t(this.textoBotaoCadastro) }}
        </v-btn>
      </v-card-actions>
    </div>

    <!-- Modal listagem recurso base -->
    <v-dialog persistent v-model="modalRecursosBase" width="900">
      <v-card>
        <div class="card-header-padding">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              {{ $t(tituloBase) }}
            </h5>
            <p class="text-sm text-body mb-0">
              {{ $t(subtituloBase) }}
            </p>
          </div>
        </div>

        <v-text-field
          hide-details
          class="input-style font-size-input text-light-input placeholder-light input-icon"
          dense
          flat
          filled
          solo
          height="39"
          v-model="search"
          :label="$t('Procurar')"
        >
          <template slot="prepend-inner">
            <v-icon color="#adb5bd" size=".875rem">fas fa-search</v-icon>
          </template>
        </v-text-field>

        <v-data-table
          :headers="headersBase"
          :items="recursosBase"
          :search="search"
          :items-per-page="pageSize"
          @click:row="abreFormRecursoFinal"
          :item-class="isStatusComum"
        >
          <template v-slot:[`item.juros`]="{ item }">
            <span>{{ item.juros | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.tituloPagar.valor`]="{ item }">
            <span>{{ item.tituloPagar.valor | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.desconto`]="{ item }">
            <span>{{ item.desconto | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.multa`]="{ item }">
            <span>{{ item.multa | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.valorUnitario`]="{ item }">
            <span>{{ item.valorUnitario | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.outrosValores`]="{ item }">
            <span>{{ item.outrosValores | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.pagamentoParcial`]="{ item }">
            <span>{{ item.pagamentoParcial | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.recebimentoParcial`]="{ item }">
            <span>{{ item.recebimentoParcial | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.valorFinal`]="{ item }">
            <span>{{ item.valorFinal | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.valor`]="{ item }">
            <span>{{ item.valor | formatoMonetario }}</span>
          </template>
          <template v-slot:[`item.ativo`]="{ item }">
            <span>{{ item.ativo ? $t("sim") : $t("nao") }}</span>
          </template>
          <template v-slot:[`item.vencimento`]="{ item }">
            <span>{{ item.vencimento | dataParaLeitura }} </span>
          </template>
          <template v-slot:[`item.nascimento`]="{ item }">
            <span>{{ item.nascimento | dataParaLeitura }} </span>
          </template>

          <template v-slot:[`item.finalVigencia`]="{ item }">
            <span>{{ item.finalVigencia | dataParaLeitura }} </span>
          </template>
          <template v-slot:[`item.inicioVigencia`]="{ item }">
            <span>{{ item.inicioVigencia | dataParaLeitura }} </span>
          </template>
          <template v-slot:[`item.apuracao`]="{ item }">
            <span>{{ $t(item.apuracao) }}</span>
          </template>
          <template v-slot:[`item.tituloPagar.vencimento`]="{ item }">
            <span>{{ item.tituloPagar.vencimento | dataParaLeitura }} </span>
          </template>
          <template v-slot:[`item.dtAlteracao`]="{ item }">
            <span>{{ item.dtAlteracao | dataHoraParaLeitura }} </span>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-btn
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            @click.stop="modalRecursosBase = false"
          >
            {{ $t("Cancelar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- criação de recurso final -->
    <v-dialog v-model="modalFormRecursoFinal" persistent max-width="700">
      <v-card>
        <v-form
          @submit.prevent="criaRecursoFinal()"
          ref="formCreate"
          v-model="CreateFormIsValid"
          lazy-validation
        >
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row v-if="modalFormRecursoFinal">
              <slot v-bind:recursoFinalAtual="recursoFinalAtual"> </slot>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="fechaFormRecursoFinal"
            >
              {{ $t("Voltar") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              id="btn--adicionar"
              @click.prevent="criaRecursoFinal"
            >
              {{ $t("Adicionar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- edição do recurso final -->
    <v-dialog v-model="modalEditaRecursoFinal" persistent max-width="700">
      <v-card>
        <v-form
          @submit.prevent="criaRecursoFinal()"
          ref="formUpdate"
          v-model="UpdateFormIsValid"
          lazy-validation
        >
          <v-spacer></v-spacer>
          <v-card-text>
            <v-row v-if="modalEditaRecursoFinal">
              <slot
                name="formEditaRecursoFinal"
                v-bind:recursoFinalAtual="recursoFinalAtual"
              ></slot>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              height="39"
              class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
              @click="cancelaEdicaoRecursoFinal()"
            >
              {{ $t("Cancelar") }}
            </v-btn>

            <v-btn
              class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
              height="39"
              @click.prevent="salvaEdicaoRecursoFinal()"
            >
              {{ $t("Confirmar") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <ErrorAlert :errors="errors" />
  </div>
</template>

<script>
import DataService from "@/services/ComumDataService";
import { objectPartiallyEqual } from "../../../../tests/e2e/support/utils";
export default {
  name: "CriaSuperSet",
  props: {
    tituloFinais: { type: String, required: true },
    subtituloFinais: { type: String, required: true },
    headersFinais: { type: Array, required: true },
    tituloBase: { type: String, required: false },
    subtituloBase: { type: String, required: false },
    headersBase: { type: Array, required: false },
    textoBotaoCadastro: { type: String, required: true },
    urlRecursoBase: { type: String, required: false },
    usaRecursoBase: { type: Boolean, default: true },
    nomeAtributo: { type: String, required: false },
    pesquisa: { type: Boolean, required: false, default: true },
    subTotal: { type: Boolean, required: false, default: false },
    payload: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    podeEditarRecursoFinal: { type: Boolean, default: false },
    podeExcluirRecursoFinal: { type: Boolean, default: true },
    errors: [],
  },
  mounted() {
    if (this.usaRecursoBase) this.buscaRecursosBase();

    setTimeout(() => {
      this.recursosFinais = this.payload;
    }, 500);
    if (this.subTotal) {
      this.calcularSubtotal();
    }
  },
  data() {
    return {
      CreateFormIsValid: false,
      UpdateFormIsValid: false,
      recursosFinais: this.payload,
      recursosBase: [],
      recursoBaseAtual: {},
      recursoFinalAtual: {
        tituloPagar: { valor: 0 },
        tituloReceber: { valor: 0 },
        desconto: 0,
        juros: 0,
        multa: 0,
        outrosValores: 0,
      },
      recursoFinalAtualOriginal: {},
      modalRecursosBase: false,
      modalFormRecursoFinal: false,
      modalEditaRecursoFinal: false,
      //Paginação
      search: "",
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      orderBy: null,
      direction: null,
      directions: [
        { text: this.$t("Crescente"), value: "ASC" },
        { text: this.$t("Decrescente"), value: "DESC" },
      ],
      subTotalValor: 0,
    };
  },
  methods: {
    clickNewRecord() {
      this.usaRecursoBase
        ? (this.modalRecursosBase = true)
        : (this.modalFormRecursoFinal = true);
    },

    buscaRecursosBase() {
      DataService.getRecursos(this.urlRecursoBase)
        .then((response) => {
          this.recursosBase = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abreFormRecursoFinal(recursoBase) {
      // Define o recursoBase sendo manipulado como o registro que foi clicado
      this.recursoBaseAtual = recursoBase;
      // abre o formulário de criação do recurso final
      this.modalFormRecursoFinal = true;
      // Fecha modal de listagem de recursosBase disponíveis
      this.modalRecursosBase = false;
    },
    fechaFormRecursoFinal() {
      this.modalFormRecursoFinal = false;
      this.recursoFinalAtual = {};
      this.$refs.formCreate.resetValidation();

      if (this.usaRecursoBase) this.modalRecursosBase = true;
    },
    async criaRecursoFinal() {
      await this.$refs.formCreate.validate();
      if (!this.CreateFormIsValid) {
        return;
      }

      if (this.usaRecursoBase) {
        // Atribui o recursoBaseAtual ao atributo definido na prop
        this.recursoFinalAtual[this.nomeAtributo] = this.recursoBaseAtual;
        //Remove o recursoBaseAtual dos recursos disponíveis
        this.removeRecursoBaseUtilizado();
      }

      // Os outros atributos vem dos campos definidos na chamada do componente,
      //  são definidos no slot default e todos tem v-model="recursoFinalAtual.atributo"
      //Adiciona recursoFinalAtual ao array de recursos selecionados
      this.recursosFinais.push(this.recursoFinalAtual);
      //comunica ao componente pai da alteração
      this.$emit("recursos", this.recursosFinais);
      this.modalFormRecursoFinal = false;
      this.$refs.formCreate.resetValidation();
      this.recursoFinalAtual = {};
      // if (this.subTotal) {
      //   this.calcularSubtotal();
      // }
    },
    removeRecursoBaseUtilizado() {
      this.recursosBase = this.recursosBase.filter((item) => {
        return item !== this.recursoBaseAtual;
      });
    },
    removeRecursoFinal(recursoFinal) {
      this.$swal(this.$t("Removido_com_sucesso"), "", "success");
      this.recursosBase.push(recursoFinal[this.nomeAtributo]);
      this.recursosFinais.splice(this.recursosFinais.indexOf(recursoFinal), 1);
      this.$emit("recursos", this.recursosFinais);
    },
    //Modulo edicao recurso final
    editaRecursoFinal(recurso) {
      this.recursoFinalAtual = { ...recurso };
      this.recursoFinalAtualOriginal = { ...recurso };
      this.modalEditaRecursoFinal = true;
    },
    async salvaEdicaoRecursoFinal() {
      await this.$refs.formUpdate.validate();
      if (!this.UpdateFormIsValid) {
        return;
      }
      //Substitui objeto antigo no array de recursos finais
      let newArray = [...this.recursosFinais];
      let recursoOriginal = newArray.find((item) => {
        return objectPartiallyEqual(item, this.recursoFinalAtualOriginal);
      });
      let oldIndex = this.recursosFinais.indexOf(recursoOriginal);
      newArray[oldIndex] = this.recursoFinalAtual;
      console.log(
        "this.recursosFinais, oldIndex",
        this.recursosFinais,
        oldIndex,
        recursoOriginal
      );

      this.recursosFinais = newArray;
      this.$emit("recursos", this.recursosFinais);
      //Limpa form,reseta validacao, mostra sucesso e fecha modal
      this.$swal.fire("Sucesso", "Edição realizada", "success");
      this.$refs.formUpdate.resetValidation();
      this.recursoFinalAtual = {};
      this.modalEditaRecursoFinal = false;
    },
    cancelaEdicaoRecursoFinal() {
      this.recursoFinalAtual = {};
      this.modalEditaRecursoFinal = false;
    },

    isStatusComum: function (item) {
      if (item.statusPedido) {
        if (item.statusPedido === "CANCELADO") {
          return "INATIVO";
        }
        return item.statusPedido;
      }

      console.log("item.finalVigencia", item.finalVigencia);
      console.log("item.finalVigencia - Date", new Date());
      if (item.finalVigencia) {
        if (new Date(item.finalVigencia) < new Date()) {
          console.log("item.finalVigencia", "VENCIDO");
          return "VENCIDO";
        }
      }

      if (item.situacao) {
        if (
          new Date(item.vencimento) < new Date() &&
          item.situacao == "PENDENTE"
        ) {
          return "VENCIDO";
        }

        return item.situacao;
      }

      if (item.status) {
        if (item.status == "DESLIGADO") {
          return "INATIVO";
        }
      }

      return item.statusComum;
    },

    arredondar(value, casasDecimais) {
      if (value === null || value === undefined) {
        return 0.0;
      }
      const factor = Math.pow(10, casasDecimais);
      return Math.round(value * factor) / factor;
    },

    calcularSubtotal() {
      console.log("calcularSubtotal", this.recursosFinais);
      if (this.nomeAtributo === "tituloPagar") {
        this.subTotalValor = this.recursosFinais.reduce((total, item) => {
          let valorTitulo =
            item.tituloPagar.valor !== undefined
              ? parseFloat(item.tituloPagar.valor)
              : 0;
          const desconto =
            item.desconto !== undefined
              ? this.arredondar(parseFloat(item.desconto), 2)
              : 0;
          const juros =
            item.juros !== undefined
              ? this.arredondar(parseFloat(item.juros), 2)
              : 0;
          const multa =
            item.multa !== undefined
              ? this.arredondar(parseFloat(item.multa), 2)
              : 0;
          const outrosValores =
            item.outrosValores !== undefined
              ? this.arredondar(parseFloat(item.outrosValores), 2)
              : 0;
          const pagamentoParcial =
            item.pagamentoParcial !== undefined
              ? this.arredondar(parseFloat(item.pagamentoParcial), 2)
              : 0;
          if (pagamentoParcial > 0) {
            valorTitulo = pagamentoParcial;
          }
          const valorCaculado =
            total +
            this.arredondar(
              valorTitulo - desconto + juros + multa + outrosValores,
              2
            );
          console.log("Valor Calculado: ", valorCaculado);
          return valorCaculado;
        }, 0);
        this.subTotalValor = this.subTotalValor.toFixed(6);
      } else {
        this.subTotalValor = this.recursosFinais.reduce((total, item) => {
          let valorTitulo =
            item.tituloReceber.valor !== undefined
              ? parseFloat(item.tituloReceber.valor)
              : 0;
          const desconto =
            item.desconto !== undefined
              ? this.arredondar(parseFloat(item.desconto), 2)
              : 0;
          const juros =
            item.juros !== undefined
              ? this.arredondar(parseFloat(item.juros), 2)
              : 0;
          const multa =
            item.multa !== undefined
              ? this.arredondar(parseFloat(item.multa), 2)
              : 0;
          const outrosValores =
            item.outrosValores !== undefined
              ? this.arredondar(parseFloat(item.outrosValores), 2)
              : 0;
          const recebimentoParcial =
            item.recebimentoParcial !== undefined
              ? this.arredondar(parseFloat(item.recebimentoParcial), 2)
              : 0;
          if (recebimentoParcial > 0) {
            valorTitulo = recebimentoParcial;
            console.log("Valor Titulo:", valorTitulo);
            console.log("Recebimento Parcial", recebimentoParcial);
          }
          return (
            total +
            this.arredondar(
              valorTitulo - desconto + juros + multa + outrosValores,
              2
            )
          );
        }, 0);
        this.subTotalValor = this.subTotalValor.toFixed(6);
      }
    },
  },
  watch: {
    recursosFinais: {
      handler: "calcularSubtotal",
      deep: true,
    },
  },
  // watch: {
  //   modalEditaRecursoFinal: function () {
  //     if (this.subTotal) {
  //       this.calcularSubtotal();
  //       console.log("Entrou no Watch: ");
  //     }
  //   },
  // },
};
</script>

<style>
#app .VENCIDO {
  color: red;
}

#app .INATIVO {
  color: red;
}
</style>

<!-- calcularSubtotal() { //calculo do subTotal de maneira mais decente, porém dando erro.
  console.log("calcularSubtotal", this.recursosFinais);

  this.subTotalValor = this.recursosFinais.reduce((total, item) => {
    const valorTitulo = 0.0;
    if (this.nomeAtributo === "tituloPagar") {
      valorTitulo =
        item.tituloPagar.valor !== undefined ? parseFloat(item.tituloPagar.valor) : 0;
    } else {
      valorTitulo =
        item.tituloReceber.valor !== undefined ? parseFloat(item.tituloReceber.valor) : 0;
    }
    const desconto = typeof item.desconto === "number" ? item.desconto : 0;
    const juros = item.juros !== undefined ? parseFloat(item.juros) : 0;
    const multa = item.multa !== undefined ? parseFloat(item.multa) : 0;
    const outrosValores =
      typeof item.outrosValores === "number" ? item.outrosValores : 0;
    const valorCaculado =
      total + (valorTitulo - desconto + juros + multa + outrosValores);
    console.log("Valor Calculado: ", valorCaculado);
    return valorCaculado;
  }, 0);
  this.subTotalValor = this.subTotalValor.toFixed(6);
},
}, -->