<template>
  <ListagemBase
    nomePlural="Pedidos"
    nomeSingular="Pedido"
    :headers="headers"
    :singleExpandableProp="true"
    :buscaSimplificada="false"
    :pesquisaDataAtualTv="true"
    :botaoImport="true"
    :opcoesLegenda="[
      { text: 'Cancelado', color: 'red' },
      { text: 'Pendente', color: 'orange' },
    ]"
  >
  </ListagemBase>
</template>

<script>
import DataService from "@/services/operacoes/PedidoDataService";

import ListagemBase from "@/components/base/Crud/ListagemBase";

export default {
  name: "ListagemPedidos",
  components: { ListagemBase },
  data() {
    return {
      DataService: DataService,
      headers: [
        { text: this.$t("Data"), value: "data", sortable: "false" },
        { text: this.$t("Status"), value: "statusPedido", sortable: "false" },
        {
          text: this.$t("Classificacao"),
          value: "classificacao",
          sortable: "false",
        },
        {
          text: this.$t("Cliente"),
          value: "cliente.fantasia",
          sortable: "false",
        },
        {
          text: this.$t("Origem"),
          value: "origem.descricao",
          sortable: "false",
        },
        {
          text: this.$t("janelaOrigemInicio"),
          value: "janelaOrigemInicio",
          sortable: "false",
        },

        {
          text: this.$t("Destino"),
          value: "destino.descricao",
          sortable: "false",
        },
        {
          text: this.$t("janelaDestinoInicio"),
          value: "janelaDestinoInicio",
          sortable: "false",
        },
        {
          text: this.$t("Valor_total"),
          value: "valorTotal",
          sortable: "false",
        },

        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
};
</script>

<style>
</style>
