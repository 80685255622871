<template>
  <v-container fluid @focus="handleFocus()">
    <div>
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding">
          <div class="d-flex align-center"></div>
        </div>

        <slot name="modals"> </slot>

        <ErrorAlert :errors="erros"></ErrorAlert>

        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-600 text-h3 text-typo mb-0">
            {{ $t("cadastro." + this.nomeSingular) }}</span
          >
        </div>
        <v-row justify="center">
          <v-col cols="12" md="12">
            <v-form @submit.prevent="submitForm" v-if="!submited">
              <v-container>
                <v-row justify="center">
                  <slot> </slot>

                  <v-col cols="12" class="text-right">
                    <v-btn
                      id="salvar"
                      style="z-index: 2"
                      @click="closeModal"
                      :loading="loading"
                      class="ma-1 font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
                      height="39"
                    >
                      {{ $t("Voltar") }}
                    </v-btn>
                    <v-btn
                      id="salvar"
                      style="z-index: 2"
                      type="submit"
                      :loading="loading"
                      class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
                      height="39"
                    >
                      {{ $t("Enviar") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>


  <script>
export default {
  name: "CadastroBase",
  props: {
    nomePlural: { type: String, required: true },
    nomeSingular: { type: String, required: true },
    submitPersonalizado: { type: Boolean, required: false, default: false }, //Se true usa o método submitForm do parent
    submited: { type: Boolean, default: false },
  },
  data() {
    return {
      loading: false,
      erros: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModalInsert");
    },
    closeSubmitModal() {
      this.$emit("submited", false);
      this.submited = false;
    },
    submitForm(event) {
      this.loading = true;

      //Usa método do componente pai se prop submitPersonalizado for true
      if (this.submitPersonalizado) {
        this.$parent
          .submitForm()
          .then(() => {
            this.loading = false;
            this.$emit("submitSuccessInsert");
            this.checkPendingTutorialSucess();
            this.submited = true;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.erros = error;
          });
      } else {
        this.$parent.DataService.create(this.limpaMascara(this.$parent.recurso))
          .then((response) => {
            this.erros = [];
            this.submited = true;
            this.loading = false;

            this.$emit("headersLocation", response.headers.location);
            this.$emit("submitSuccessInsert");
            this.checkPendingTutorialSucess();

            if (this.$parent.DeveLimparForm) {
              this.$parent.limpaForm();
            } else {
              this.$parent.recurso = {};
            }
          })
          .catch((error) => {
            this.loading = false;
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            this.$emit("submitErrorInsert");
            this.erros = error.response.data.errors;
          });
      }
    },
  },
};

document.addEventListener("keydown", function (event) {
  if (event.key === "Enter") {
    //Criar verficador que encontra qual elemento tem o foco
    var elem = document.activeElement;

    //Se o nome do campo tiver  a palavra cBarras faça...
    if (elem.name.indexOf("cBarras") != -1) {
      //impedir o formulário de ser enviado
      console.log("Prevent cBarras");
      event.preventDefault();
      return false;
    }

    if (
      elem.name.indexOf("searchProdutos") != -1 ||
      elem.name.indexOf("searchProdutosPedido") != -1
    ) {
      //impedir o formulário de ser enviado
      console.log("Prevent searchProdutos");
      event.preventDefault();
      return false;
    }
  }
});
</script>

<style scoped>
</style>
