<template>
  <div>
    <v-dialog
      v-model="confirmaExportacaoConsumo"
      persistent
      max-width="400"
      class="dialog-deletado"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("Selecione_tipo_exportacao")
        }}</v-card-title>
        <v-card-text>
          <v-select
            v-model="tipoExportacao"
            :items="[
              { value: 'CLIENTE', text: $t('Cliente') },
              { value: 'PRODUTO', text: $t('Produto') },
              { value: 'SOLICITACOES', text: $t('Solicitacoes') },
            ]"
            item-text="text"
            item-value="value"
            :label="$t('Tipo')"
          ></v-select>
          <v-row class="mt-4" v-if="tipoExportacao === 'SOLICITACOES'">
            <v-col cols="12" md="6">
              <CampoData
                @date="inicioPeriodo = $event"
                label="Inicio_do_periodo"
                name="dataInicio"
              />
            </v-col>
            <v-col cols="12" md="6">
              <CampoData
                @date="finalPeriodo = $event"
                label="Final_do_periodo"
                name="dataFim"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            height="39"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            text
            @click="confirmaExportacaoConsumo = false"
            :loading="loading"
          >
            {{ $t("Cancelar") }}
          </v-btn>

          <v-btn
            class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
            height="39"
            text
            @click="exportaConsumo"
            :loading="loading"
          >
            {{ $t("Exportar") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ListagemBase
      nomePlural="PedidoConsumiveis"
      nomeSingular="PedidoConsumivel"
      :headers="headers"
      :opcoesLegenda="[{ text: 'Pendente', color: 'orange' }]"
      :loading="loading"
      stringCadastro="Nova_solicitacao"
    >
      <template v-slot:upload>
        <v-btn
          class="ma-1 font-weight-bold text-none btn-primary bg-gradient-primary py-3 px-6 ms-3"
          height="39"
          @click="confirmaExportacao()"
          v-can="'ROLE_PedidoConsumivel_Value'"
          id="btn--consumo12Meses"
          :loading="loading"
        >
          <v-icon left dark> mdi-table-arrow-right </v-icon>
          {{ $t("Consumo") }}
        </v-btn>
      </template>
    </ListagemBase>
  </div>
</template>

<script>
import ListagemBase from "@/components/base/Crud/ListagemBase";
import ComumDataService from "@/services/ComumDataService";
import DataService from "@/services/recursos/PedidoConsumivelDataService";
export default {
  name: "ListagemPedidos",
  components: { ListagemBase, ComumDataService },
  data() {
    return {
      DataService: DataService,
      loading: false,
      confirmaExportacaoConsumo: false,
      tipoExportacao: "",
      inicioPeriodo: "",
      finalPeriodo: "",
      headers: [
        { text: this.$t("Id"), value: "id", sortable: "false" },
        { text: this.$t("Status"), value: "statusPedido", sortable: "false" },
        {
          text: this.$t("Data"),
          value: "dataPedido",
          sortable: "false",
        },
        {
          text: this.$t("Centro de Custo"),
          value: "centroCusto.descricao",
          sortable: "false",
        },
        {
          text: this.$t("dataSolicitacao"),
          value: "dtInclusao",
          sortable: "false",
        },
        {
          text: this.$t("Solicitante"),
          value: "solicitante.nome",
          sortable: "false",
        },
        {
          text: this.$t("Atividade"),
          value: "actionsPedidoConsumivel",
          sortable: "false",
        },
        { text: this.$t("Acoes"), value: "actions", sortable: "false" },
      ],
    };
  },
  methods: {
    confirmaExportacao() {
      this.confirmaExportacaoConsumo = true;
    },

    exportaConsumo() {
      this.loading = true;
      if (this.tipoExportacao === "CLIENTE") {
        ComumDataService.put("pedidoConsumivel/exportUse12months", {
          responseType: "blob",
        })
          .then((res) => {
            this.confirmaExportacaoConsumo = false;
            this.loading = false;
            this.download(res.data, "consumo_cliente.csv", "text/csv");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((err) => {
            this.confirmaExportacaoConsumo = false;
            this.loading = false;
            console.log("err", err);
          });
      } else if (this.tipoExportacao === "PRODUTO") {
        ComumDataService.put("pedidoConsumivel/exportProductUse12months", {
          responseType: "blob",
        })
          .then((res) => {
            this.confirmaExportacaoConsumo = false;
            this.loading = false;
            this.download(res.data, "consumo_produto.csv", "text/csv");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((err) => {
            this.confirmaExportacaoConsumo = false;
            this.loading = false;
            console.log("err", err);
          });
      } else if (this.tipoExportacao === "SOLICITACOES") {
        ComumDataService.put("pedidoConsumivel/exportRequests", {
          inicioPeriodo: this.inicioPeriodo,
          finalPeriodo: this.finalPeriodo,
          responseType: "blob",
        })
          .then((res) => {
            this.confirmaExportacaoConsumo = false;
            this.loading = false;
            this.download(res.data, "consumo_produto.csv", "text/csv");
            this.$swal.fire(
              this.$t("Sucesso"),
              this.$t("Documento_gerado_com_sucesso"),
              "success"
            );
          })
          .catch((err) => {
            this.confirmaExportacaoConsumo = false;
            this.loading = false;
            console.log("err", err);
          });
      }
    },

    download(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },
  },
};
</script>

<style>
</style>
